import { useState } from 'react';
import { Button, HStack, Input, Text } from '@chakra-ui/react';
import FraudInfoWindow from './components/AddButtonGroup/components/FraudInfoWindow';
import { useDispatch, useSelector } from 'react-redux';
import { FraudFlagDto, FraudFlagType } from '../../api/sms-backoffice-api';
import { setDetectionType } from '../../store/detectionTypeSlice';
import DetectionTypeSwitch from './components/DetectionTypeSwitch';
import FraudFlagTable from './components/FraudFlagTable';
import { AppDispatch, RootState } from '../../store';
import { fetchFraudData } from '../../store/fraudDataSlice';

const FraudFlags = () => {
  const [currentItem] = useState<FraudFlagDto | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const dispatch = useDispatch<AppDispatch>();

  const handleSave = (newDetectionType: string) => {
    dispatch(setDetectionType(newDetectionType.replace(/\s+/g, '_')));
    setIsModalOpen(false);
  };

  const detectionType = useSelector((state: RootState) => state.detectionType.value);
  const formattedDetectionType = detectionType.replace('_', ' ');

  const handleSearch = () => {
    if (!searchTerm.trim()) return;

    const searchField = detectionType === 'BANK_DETAILS' ? 'value2' : 'value';

    console.log(
      `Fetching Detection Type: ${detectionType}, Search Field: ${searchField}, Keywords: ${searchTerm}`,
    );

    dispatch(
      fetchFraudData({
        fraudFlagType: detectionType as FraudFlagType,
        searchQuery: searchTerm,
        page: 1,
        pageSize: 10,
      }),
    );
  };

  return (
    <div>
      <Text fontSize="lg">Detection Type</Text>
      <DetectionTypeSwitch />

      <HStack my={2}>
        {detectionType === 'BANK_DETAILS' ? (
          <Input
            width={300}
            placeholder={`Search by ACCOUNT NUMBER`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        ) : (
          <Input
            width={300}
            placeholder={`Search by ${formattedDetectionType}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
        <Button onClick={handleSearch}>Search</Button>
      </HStack>

      <FraudFlagTable />

      {currentItem && (
        <FraudInfoWindow
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          item={currentItem}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default FraudFlags;
