/* tslint:disable */
/* eslint-disable */
/**
 * SMS Backoffice Api Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AmlKycResultDto
 */
export interface AmlKycResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof AmlKycResultDto
     */
    'phoneCallConfirmation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AmlKycResultDto
     */
    'kycVerified'?: boolean;
}
/**
 * 
 * @export
 * @interface AuthTokenDto
 */
export interface AuthTokenDto {
    /**
     * 
     * @type {string}
     * @memberof AuthTokenDto
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    'slot'?: number;
    /**
     * 
     * @type {CustomerType}
     * @memberof CustomerDto
     */
    'type'?: CustomerType;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {TncDetailsDto}
     * @memberof CustomerDto
     */
    'tncDetails'?: TncDetailsDto;
    /**
     * 
     * @type {KycDetailsDto}
     * @memberof CustomerDto
     */
    'kycDetails'?: KycDetailsDto;
    /**
     * 
     * @type {AmlKycResultDto}
     * @memberof CustomerDto
     */
    'amlKycResult'?: AmlKycResultDto;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'updatedOn'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerType = {
    IndividualPerson: 'INDIVIDUAL_PERSON',
    JointPerson: 'JOINT_PERSON',
    Executor: 'EXECUTOR',
    CompanyDirector: 'COMPANY_DIRECTOR',
    PowerOfAttorney: 'POWER_OF_ATTORNEY'
} as const;

export type CustomerType = typeof CustomerType[keyof typeof CustomerType];


/**
 * 
 * @export
 * @interface FraudDetectionResultDto
 */
export interface FraudDetectionResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof FraudDetectionResultDto
     */
    'fraudResult'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FraudDetectionResultDto
     */
    'fraudSummary'?: string | null;
}
/**
 * 
 * @export
 * @interface FraudFlagDto
 */
export interface FraudFlagDto {
    /**
     * 
     * @type {string}
     * @memberof FraudFlagDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {FraudFlagType}
     * @memberof FraudFlagDto
     */
    'type'?: FraudFlagType;
    /**
     * 
     * @type {string}
     * @memberof FraudFlagDto
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof FraudFlagDto
     */
    'value2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FraudFlagDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FraudFlagDto
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof FraudFlagDto
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof FraudFlagDto
     */
    'updatedOn'?: string | null;
}


/**
 * 
 * @export
 * @interface FraudFlagPaginationResponseDto
 */
export interface FraudFlagPaginationResponseDto {
    /**
     * 
     * @type {Array<FraudFlagDto>}
     * @memberof FraudFlagPaginationResponseDto
     */
    'items'?: Array<FraudFlagDto>;
    /**
     * 
     * @type {number}
     * @memberof FraudFlagPaginationResponseDto
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof FraudFlagPaginationResponseDto
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof FraudFlagPaginationResponseDto
     */
    'pageCount'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FraudFlagType = {
    Email: 'EMAIL',
    FullName: 'FULL_NAME',
    BankDetails: 'BANK_DETAILS',
    Postcode: 'POSTCODE',
    Mobile: 'MOBILE',
    Srn: 'SRN',
    StockCode: 'STOCK_CODE'
} as const;

export type FraudFlagType = typeof FraudFlagType[keyof typeof FraudFlagType];


/**
 * 
 * @export
 * @interface KycDetailsDto
 */
export interface KycDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof KycDetailsDto
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycDetailsDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycDetailsDto
     */
    'fullAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycDetailsDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycDetailsDto
     */
    'riskLevel'?: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'plainPassword': string;
}
/**
 * 
 * @export
 * @interface NaturalPersonDto
 */
export interface NaturalPersonDto {
    /**
     * 
     * @type {string}
     * @memberof NaturalPersonDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof NaturalPersonDto
     */
    'fullName'?: string;
}
/**
 * 
 * @export
 * @interface SaleDetailsDto
 */
export interface SaleDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof SaleDetailsDto
     */
    'contactEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetailsDto
     */
    'contactFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetailsDto
     */
    'contactMobileNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetailsDto
     */
    'shareholderFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetailsDto
     */
    'shareholderPostcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetailsDto
     */
    'bankBsbNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetailsDto
     */
    'bankAccountNumber'?: string;
    /**
     * 
     * @type {Array<NaturalPersonDto>}
     * @memberof SaleDetailsDto
     */
    'naturalPersons'?: Array<NaturalPersonDto>;
    /**
     * 
     * @type {Array<ShareParcelDto>}
     * @memberof SaleDetailsDto
     */
    'shareParcels'?: Array<ShareParcelDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ServiceLevelType = {
    Diy: 'DIY',
    Value: 'VALUE',
    Express: 'EXPRESS',
    DeceasedEstate: 'DECEASED_ESTATE',
    International: 'INTERNATIONAL'
} as const;

export type ServiceLevelType = typeof ServiceLevelType[keyof typeof ServiceLevelType];


/**
 * 
 * @export
 * @interface ShareParcelDto
 */
export interface ShareParcelDto {
    /**
     * 
     * @type {string}
     * @memberof ShareParcelDto
     */
    'securityReferenceNumber'?: string;
}
/**
 * 
 * @export
 * @interface ShareSaleDto
 */
export interface ShareSaleDto {
    /**
     * 
     * @type {string}
     * @memberof ShareSaleDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareSaleDto
     */
    'submissionId'?: string;
    /**
     * 
     * @type {ServiceLevelType}
     * @memberof ShareSaleDto
     */
    'serviceLevelType'?: ServiceLevelType;
    /**
     * 
     * @type {string}
     * @memberof ShareSaleDto
     */
    'contactEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareSaleDto
     */
    'contactFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareSaleDto
     */
    'contactMobileNumber'?: string;
    /**
     * 
     * @type {Array<CustomerDto>}
     * @memberof ShareSaleDto
     */
    'customers'?: Array<CustomerDto>;
    /**
     * 
     * @type {string}
     * @memberof ShareSaleDto
     */
    'stateTransitionedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareSaleDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShareSaleDto
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareSaleDto
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareSaleDto
     */
    'updatedOn'?: string | null;
}


/**
 * 
 * @export
 * @interface ShareSalePaginationResponseDto
 */
export interface ShareSalePaginationResponseDto {
    /**
     * 
     * @type {Array<ShareSaleDto>}
     * @memberof ShareSalePaginationResponseDto
     */
    'items'?: Array<ShareSaleDto>;
    /**
     * 
     * @type {number}
     * @memberof ShareSalePaginationResponseDto
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShareSalePaginationResponseDto
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShareSalePaginationResponseDto
     */
    'pageCount'?: number;
}
/**
 * 
 * @export
 * @interface TallyDataDto
 */
export interface TallyDataDto {
    /**
     * 
     * @type {string}
     * @memberof TallyDataDto
     */
    'responseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyDataDto
     */
    'submissionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyDataDto
     */
    'respondentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyDataDto
     */
    'formId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyDataDto
     */
    'formName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyDataDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<TallyFieldDto>}
     * @memberof TallyDataDto
     */
    'fields'?: Array<TallyFieldDto>;
}
/**
 * 
 * @export
 * @interface TallyEventDto
 */
export interface TallyEventDto {
    /**
     * 
     * @type {string}
     * @memberof TallyEventDto
     */
    'eventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyEventDto
     */
    'eventType'?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyEventDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {TallyDataDto}
     * @memberof TallyEventDto
     */
    'data'?: TallyDataDto;
}
/**
 * 
 * @export
 * @interface TallyFieldDto
 */
export interface TallyFieldDto {
    /**
     * 
     * @type {string}
     * @memberof TallyFieldDto
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyFieldDto
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyFieldDto
     */
    'type'?: string;
    /**
     * 
     * @type {object}
     * @memberof TallyFieldDto
     */
    'value'?: object;
    /**
     * 
     * @type {Array<TallyOptionDto>}
     * @memberof TallyFieldDto
     */
    'options'?: Array<TallyOptionDto>;
}
/**
 * 
 * @export
 * @interface TallyOptionDto
 */
export interface TallyOptionDto {
    /**
     * 
     * @type {string}
     * @memberof TallyOptionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TallyOptionDto
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface TncDetailsDto
 */
export interface TncDetailsDto {
    /**
     * 
     * @type {boolean}
     * @memberof TncDetailsDto
     */
    'accepted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TncDetailsDto
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TncDetailsDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof TncDetailsDto
     */
    'mobileNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TncDetailsDto
     */
    'bankAccountAuthorisation'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TncDetailsDto
     */
    'acceptedOn'?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest?: LoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest?: LoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest?: LoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<AuthTokenDto> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {LoginRequest} [loginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(loginRequest?: LoginRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Accepts an incoming tally event payload and processes it.
         * @summary Consumes a tally event
         * @param {string} [tallySignature] 
         * @param {TallyEventDto} [tallyEventDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeTallyEvent: async (tallySignature?: string, tallyEventDto?: TallyEventDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (tallySignature != null) {
                localVarHeaderParameter['tally-signature'] = String(tallySignature);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tallyEventDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * Accepts an incoming tally event payload and processes it.
         * @summary Consumes a tally event
         * @param {string} [tallySignature] 
         * @param {TallyEventDto} [tallyEventDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumeTallyEvent(tallySignature?: string, tallyEventDto?: TallyEventDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumeTallyEvent(tallySignature, tallyEventDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventsApi.consumeTallyEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * Accepts an incoming tally event payload and processes it.
         * @summary Consumes a tally event
         * @param {string} [tallySignature] 
         * @param {TallyEventDto} [tallyEventDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeTallyEvent(tallySignature?: string, tallyEventDto?: TallyEventDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.consumeTallyEvent(tallySignature, tallyEventDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * Accepts an incoming tally event payload and processes it.
     * @summary Consumes a tally event
     * @param {string} [tallySignature] 
     * @param {TallyEventDto} [tallyEventDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public consumeTallyEvent(tallySignature?: string, tallyEventDto?: TallyEventDto, options?: RawAxiosRequestConfig) {
        return EventsApiFp(this.configuration).consumeTallyEvent(tallySignature, tallyEventDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FraudFlagsApi - axios parameter creator
 * @export
 */
export const FraudFlagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {FraudFlagDto} [fraudFlagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFraudFlag: async (fraudFlagType: FraudFlagType, fraudFlagDto?: FraudFlagDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fraudFlagType' is not null or undefined
            assertParamExists('createFraudFlag', 'fraudFlagType', fraudFlagType)
            const localVarPath = `/v1/fraud-flags/{fraudFlagType}`
                .replace(`{${"fraudFlagType"}}`, encodeURIComponent(String(fraudFlagType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fraudFlagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaleDetailsDto} [saleDetailsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectFrauds: async (saleDetailsDto?: SaleDetailsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/fraud-flags/detect-frauds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saleDetailsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} [searchQuery] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFraudFlags: async (fraudFlagType: FraudFlagType, searchQuery?: string, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fraudFlagType' is not null or undefined
            assertParamExists('listFraudFlags', 'fraudFlagType', fraudFlagType)
            const localVarPath = `/v1/fraud-flags/{fraudFlagType}`
                .replace(`{${"fraudFlagType"}}`, encodeURIComponent(String(fraudFlagType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFraudFlag: async (fraudFlagType: FraudFlagType, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fraudFlagType' is not null or undefined
            assertParamExists('removeFraudFlag', 'fraudFlagType', fraudFlagType)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeFraudFlag', 'id', id)
            const localVarPath = `/v1/fraud-flags/{fraudFlagType}/{id}`
                .replace(`{${"fraudFlagType"}}`, encodeURIComponent(String(fraudFlagType)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} id 
         * @param {FraudFlagDto} [fraudFlagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFraudFlag: async (fraudFlagType: FraudFlagType, id: string, fraudFlagDto?: FraudFlagDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fraudFlagType' is not null or undefined
            assertParamExists('updateFraudFlag', 'fraudFlagType', fraudFlagType)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFraudFlag', 'id', id)
            const localVarPath = `/v1/fraud-flags/{fraudFlagType}/{id}`
                .replace(`{${"fraudFlagType"}}`, encodeURIComponent(String(fraudFlagType)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fraudFlagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FraudFlagsApi - functional programming interface
 * @export
 */
export const FraudFlagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FraudFlagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {FraudFlagDto} [fraudFlagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFraudFlag(fraudFlagType: FraudFlagType, fraudFlagDto?: FraudFlagDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FraudFlagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFraudFlag(fraudFlagType, fraudFlagDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FraudFlagsApi.createFraudFlag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SaleDetailsDto} [saleDetailsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detectFrauds(saleDetailsDto?: SaleDetailsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FraudDetectionResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detectFrauds(saleDetailsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FraudFlagsApi.detectFrauds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} [searchQuery] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFraudFlags(fraudFlagType: FraudFlagType, searchQuery?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FraudFlagPaginationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFraudFlags(fraudFlagType, searchQuery, page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FraudFlagsApi.listFraudFlags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFraudFlag(fraudFlagType: FraudFlagType, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFraudFlag(fraudFlagType, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FraudFlagsApi.removeFraudFlag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} id 
         * @param {FraudFlagDto} [fraudFlagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFraudFlag(fraudFlagType: FraudFlagType, id: string, fraudFlagDto?: FraudFlagDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FraudFlagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFraudFlag(fraudFlagType, id, fraudFlagDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FraudFlagsApi.updateFraudFlag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FraudFlagsApi - factory interface
 * @export
 */
export const FraudFlagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FraudFlagsApiFp(configuration)
    return {
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {FraudFlagDto} [fraudFlagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFraudFlag(fraudFlagType: FraudFlagType, fraudFlagDto?: FraudFlagDto, options?: RawAxiosRequestConfig): AxiosPromise<FraudFlagDto> {
            return localVarFp.createFraudFlag(fraudFlagType, fraudFlagDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaleDetailsDto} [saleDetailsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectFrauds(saleDetailsDto?: SaleDetailsDto, options?: RawAxiosRequestConfig): AxiosPromise<FraudDetectionResultDto> {
            return localVarFp.detectFrauds(saleDetailsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} [searchQuery] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFraudFlags(fraudFlagType: FraudFlagType, searchQuery?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<FraudFlagPaginationResponseDto> {
            return localVarFp.listFraudFlags(fraudFlagType, searchQuery, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFraudFlag(fraudFlagType: FraudFlagType, id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeFraudFlag(fraudFlagType, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} id 
         * @param {FraudFlagDto} [fraudFlagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFraudFlag(fraudFlagType: FraudFlagType, id: string, fraudFlagDto?: FraudFlagDto, options?: RawAxiosRequestConfig): AxiosPromise<FraudFlagDto> {
            return localVarFp.updateFraudFlag(fraudFlagType, id, fraudFlagDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FraudFlagsApi - object-oriented interface
 * @export
 * @class FraudFlagsApi
 * @extends {BaseAPI}
 */
export class FraudFlagsApi extends BaseAPI {
    /**
     * 
     * @param {FraudFlagType} fraudFlagType 
     * @param {FraudFlagDto} [fraudFlagDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudFlagsApi
     */
    public createFraudFlag(fraudFlagType: FraudFlagType, fraudFlagDto?: FraudFlagDto, options?: RawAxiosRequestConfig) {
        return FraudFlagsApiFp(this.configuration).createFraudFlag(fraudFlagType, fraudFlagDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SaleDetailsDto} [saleDetailsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudFlagsApi
     */
    public detectFrauds(saleDetailsDto?: SaleDetailsDto, options?: RawAxiosRequestConfig) {
        return FraudFlagsApiFp(this.configuration).detectFrauds(saleDetailsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FraudFlagType} fraudFlagType 
     * @param {string} [searchQuery] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudFlagsApi
     */
    public listFraudFlags(fraudFlagType: FraudFlagType, searchQuery?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return FraudFlagsApiFp(this.configuration).listFraudFlags(fraudFlagType, searchQuery, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FraudFlagType} fraudFlagType 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudFlagsApi
     */
    public removeFraudFlag(fraudFlagType: FraudFlagType, id: string, options?: RawAxiosRequestConfig) {
        return FraudFlagsApiFp(this.configuration).removeFraudFlag(fraudFlagType, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FraudFlagType} fraudFlagType 
     * @param {string} id 
     * @param {FraudFlagDto} [fraudFlagDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudFlagsApi
     */
    public updateFraudFlag(fraudFlagType: FraudFlagType, id: string, fraudFlagDto?: FraudFlagDto, options?: RawAxiosRequestConfig) {
        return FraudFlagsApiFp(this.configuration).updateFraudFlag(fraudFlagType, id, fraudFlagDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShareSaleApi - axios parameter creator
 * @export
 */
export const ShareSaleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lists all share sales with pagination
         * @summary List share sales
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [viewByLatestStateTransition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listShareSales: async (page?: number, pageSize?: number, viewByLatestStateTransition?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/share-sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (viewByLatestStateTransition !== undefined) {
                localVarQueryParameter['viewByLatestStateTransition'] = viewByLatestStateTransition;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShareSaleApi - functional programming interface
 * @export
 */
export const ShareSaleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShareSaleApiAxiosParamCreator(configuration)
    return {
        /**
         * Lists all share sales with pagination
         * @summary List share sales
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [viewByLatestStateTransition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listShareSales(page?: number, pageSize?: number, viewByLatestStateTransition?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareSalePaginationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listShareSales(page, pageSize, viewByLatestStateTransition, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShareSaleApi.listShareSales']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShareSaleApi - factory interface
 * @export
 */
export const ShareSaleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShareSaleApiFp(configuration)
    return {
        /**
         * Lists all share sales with pagination
         * @summary List share sales
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [viewByLatestStateTransition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listShareSales(page?: number, pageSize?: number, viewByLatestStateTransition?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ShareSalePaginationResponseDto> {
            return localVarFp.listShareSales(page, pageSize, viewByLatestStateTransition, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShareSaleApi - object-oriented interface
 * @export
 * @class ShareSaleApi
 * @extends {BaseAPI}
 */
export class ShareSaleApi extends BaseAPI {
    /**
     * Lists all share sales with pagination
     * @summary List share sales
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [viewByLatestStateTransition] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareSaleApi
     */
    public listShareSales(page?: number, pageSize?: number, viewByLatestStateTransition?: boolean, options?: RawAxiosRequestConfig) {
        return ShareSaleApiFp(this.configuration).listShareSales(page, pageSize, viewByLatestStateTransition, options).then((request) => request(this.axios, this.basePath));
    }
}



