import React from 'react';

import { Textarea } from '@chakra-ui/react';
import InputBar from '../../../../../../../../../Common/InputBar';

import InputBarWithInitialI from '../../../../../../../InputBarWithInitialI';
import { FraudFlagDto } from '../../../../../../../../../../api/sms-backoffice-api';

interface Props {
  detectionType: string;
  value: string;
  value2?: string;
  note?: string;
  setValue: (value: string) => void;
  setValue2?: (value2: string) => void;
  setNote?: (note: string) => void;
  item?: FraudFlagDto;
}

const FraudInfoInput = ({
  detectionType,
  value,
  value2,
  note,
  setValue,
  setValue2,
  setNote,
  item,
}: Props) => {
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleValue2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setValue2) {
      setValue2(event.target.value);
    }
  };

  return (
    <div>
      {detectionType === 'FULL_NAME' && (
        <InputBar
          inputName="Full Name"
          inputType="text"
          value={value}
          onChange={handleValueChange}
        />
      )}
      {detectionType === 'MOBILE' && (
        <InputBar inputName="Mobile" inputType="text" value={value} onChange={handleValueChange} />
      )}
      {detectionType === 'EMAIL' && (
        <InputBar inputName="Email" inputType="text" value={value} onChange={handleValueChange} />
      )}

      {detectionType === 'SRN' && (
        <InputBarWithInitialI
          inputName="SRN"
          value={value}
          onChange={setValue}
          isUpdateMode={!!item?.id}
        />
      )}

      {detectionType === 'BANK_DETAILS' && (
        <>
          <InputBar
            inputName="BSB Number"
            inputType="number"
            value={value}
            onChange={handleValueChange}
          />
          <InputBar
            inputName="Account Number"
            inputType="number"
            value={value2}
            onChange={handleValue2Change}
          />
        </>
      )}
      {detectionType === 'POSTCODE' && (
        <InputBar
          inputName="Shareholder Postcode"
          inputType="number"
          value={value}
          onChange={handleValueChange}
        />
      )}
      {detectionType === 'STOCK_CODE' && (
        <InputBar
          inputName="Stock Code"
          inputType="text"
          value={value}
          onChange={handleValueChange}
        />
      )}
      <div>Note:</div>
      <Textarea value={note} onChange={(e) => setNote?.(e.target.value)} size="lg" height="80px" />
    </div>
  );
};

export default FraudInfoInput;
