import { Button, ButtonGroup } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { setDetectionType } from '../../../../store/detectionTypeSlice';
import { RootState } from '../../../../store';

const DetectionTypeSwitch = () => {
  const detectionType = useSelector((state: RootState) => state.detectionType.value);
  const dispatch = useDispatch();

  const handleButtonClick = (newType: string) => {
    const formattedType = newType.toUpperCase().replace(/\s+/g, '_');
    dispatch(setDetectionType(formattedType));
  };

  return (
    <ButtonGroup size="sm" spacing={2} isAttached>
      <Button
        onClick={() => handleButtonClick('FULL NAME')}
        colorScheme={detectionType === 'FULL_NAME' ? 'teal' : 'gray'}
      >
        Full Name
      </Button>
      <Button
        onClick={() => handleButtonClick('MOBILE')}
        colorScheme={detectionType === 'MOBILE' ? 'teal' : 'gray'}
      >
        Mobile
      </Button>
      <Button
        onClick={() => handleButtonClick('EMAIL')}
        colorScheme={detectionType === 'EMAIL' ? 'teal' : 'gray'}
      >
        Email
      </Button>
      <Button
        onClick={() => handleButtonClick('SRN')}
        colorScheme={detectionType === 'SRN' ? 'teal' : 'gray'}
      >
        SRN
      </Button>
      <Button
        onClick={() => handleButtonClick('BANK DETAILS')}
        colorScheme={detectionType === 'BANK_DETAILS' ? 'teal' : 'gray'}
      >
        Bank Details
      </Button>
      <Button
        onClick={() => handleButtonClick('POSTCODE')}
        colorScheme={detectionType === 'POSTCODE' ? 'teal' : 'gray'}
      >
        Postcode
      </Button>{' '}
      <Button
        onClick={() => handleButtonClick('STOCK CODE')}
        colorScheme={detectionType === 'STOCK_CODE' ? 'teal' : 'gray'}
      >
        Stock Code
      </Button>
    </ButtonGroup>
  );
};

export default DetectionTypeSwitch;
